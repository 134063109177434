<template>
    <div class="content video-content" style="min-height: 200px;">
        <section class="container">
            <div style="background-color: #fff;padding: 17px 20px">
                <el-page-header @back="goBack" content="电子图纸"> </el-page-header>
            </div>
        </section>
        <section class="container" style="padding-bottom: 0px;">
            <div class="desc">
                <div class="stepPics" style="overflow: hidden; font-size: 0px;">
                    <img data-v-34a1ed88="" preview="0" v-for="item in list" :key="item"
                        :src="item"
                        width="100%" alt="" data-pswp-uid="1">
                </div>
            </div>
        </section>
        <el-backtop></el-backtop>
    </div>
</template>

<script>
    import { videoPic } from '../../api';
    export default {
        data() {
            return {
                id: this.$route.query.id,
                list:[],
            };
        },
        mounted() {
            const self = this;
            self.getPics();
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            getPics() {
                const self = this;
                let data={
                    id:self.id
                };
                videoPic(data).then(res => {
                    console.log(res);
                    if(res.code!=0){
                        this.list=res.data;
                    }
                });
            }
        }
    }
</script>